import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Heading, Box, Flex, Text } from "@raca2022/uikit";
import Arow from "../../assets/Footer/arrow.svg";
import {
  FootterAndOutTeam,
  FooterAndOutTeamContainer,
  Outteam,
  Title,
  Teams,
  FootterFootball,
  FooterItem,
  SOCIAL,
  CoppyRight,
  OutteamFloor,
  FooterLogo,
  FooterConten,
  FooterItemElm,
  BoxIconText,
  LogoFooter,
} from "./style";

import { DiscordIcon, LogoAirPayOne, TelegramIcon, TwitterIcon , TiktokIcon , YoutubeIcon } from "../Svg";
import Logo from "../Menu/Logo/Logo";

// const ArrSociical = [

// ];

interface PropsSection {
  tokennomics?: any;
  roadmap?: any;
  coreTeam?: any;
}

const ScrollToSection = (props: any) => {
  props.current.scrollIntoView({ behavior: "smooth" });
};

const Footter: React.FC<PropsSection> = ({ tokennomics, roadmap }) => {
  return (
    <FootterAndOutTeam>
      <>
        <FootterFootball>
          <Box>
            <LogoFooter>
              <a href="/">
                <LogoAirPayOne />
              </a>
              <p>AirPayOne</p>
            </LogoFooter>
            <div>
              <p className="view_sp">support@airpro.io</p>
            </div>
          </Box>
          <FooterConten>
            <FooterItem>
              <h1>Products</h1>
              <FooterItemElm>
                <a href="/">Perpetual</a>
                <a href="/">Staking</a>
                <a href="#">
                  Docs
                </a>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1>Service</h1>
              <FooterItemElm>
                <a href="#" onClick={() => ScrollToSection(tokennomics)}>App Download</a>
                <a href="#" onClick={() => ScrollToSection(roadmap)}>Affiliates</a>
              </FooterItemElm>
            </FooterItem>
            {/* <FooterItem>
              <h1>Support</h1>
              <FooterItemElm>
                <a href="#">API</a>
              </FooterItemElm>
            </FooterItem> */}
            <FooterItem>
              <h1>About Us</h1>
              <FooterItemElm>
                <a href="/" target="_blank" rel="noreferrer">
                  Announcements
                </a>
                <a href="/" target="_blank" rel="noreferrer">
                  Terms of Service
                </a>
                <a href="/" target="_blank" rel="noreferrer">
                  Privacy
                </a>
              </FooterItemElm>
            </FooterItem>
            <FooterItem>
              <h1>Community</h1>
              <FooterItemElm>
                <Flex style={{ gap: "16px" }}>
                  <a href="http://x.com/Airpayone" target="_blank" rel="noreferrer">
                    <TwitterIcon />
                  </a>
                  <a href="http://t.me/airpayone" target="_blank" rel="noreferrer">
                    <TelegramIcon />
                  </a>
                  <a href="https://t.me/airpayonecommunity" target="_blank" rel="noreferrer">
                    <TelegramIcon />
                  </a>
                  <a href="http://tiktok.com/@airpayone" target="_blank" rel="noreferrer">
                    <TiktokIcon />
                  </a>
                  <a href="https://www.youtube.com/@AirpayoneOfficial" target="_blank" rel="noreferrer">
                    <YoutubeIcon />
                  </a>
                </Flex>
              </FooterItemElm>
            </FooterItem>
          </FooterConten>
        </FootterFootball>
        <CoppyRight>
          <small>
            2023 AirPayOne™ © Copyright 2022-2023 AirPayOne Corporation. All
            Right Reserved
          </small>
        </CoppyRight>
      </>
    </FootterAndOutTeam>
  );
};
export default Footter;
